<template>
  <StaticView :heading="$t('pages.imprint')">
    <!-- GERMAN -->
    <div v-if="$i18n.locale === 'de'">
      <div class="grid grid-cols-2 grid-rows-auto leading-9 text-darkerGray">
        <div class="mb-10">
          <p class="imp-header">Angaben gemäss § 5 TMG</p>
          <p>ESG Analytics GmbH</p>
          <p>Von-Werth-Straße 15</p>
          <p>50670 Köln</p>
          <p>Deutschland</p>
          <p class="mt-4">Handelsregister: HRB 61018</p>
          <p>Registergericht: Amtsgericht Köln</p>
          <p class="mt-4">Vertreten durch:</p>
          <p>Dr. Markus G. Bell (Geschäftsführer)</p>
        </div>
        <div>
          <p class="imp-header">Kontakt</p>
          <p>support@ecore-rating.com</p>
        </div>
        <div>
          <p class="imp-header">Redaktionell Verantwortlicher</p>
          <p>Dr. Markus G. Bell</p>
          <p>ESG Analytics GmbH</p>
          <p>Von-Werth-Straße 15</p>
          <p>50670 Köln</p>
          <p>info@ecore-scoring.com</p>
          <p>www.ecore-scoring.com</p>
        </div>
      </div>
      <div
        class="grid grid-rows-auto leading-8 mt-7 text-darkerGray text-justify"
      >
        <div class="my-5">
          <p class="imp-header">EU-Streitschlichtung</p>
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr.
          </p>
          <p class="mt-3">
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <p class="mt-3">
            Verbraucherstreitbeilegung/Universalschlichtungsstelle
          </p>
          <p class="mt-3">
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </div>

        <div class="my-5">
          <p class="imp-header">Haftung für Inhalte</p>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          <p class="mt-3">
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
        </div>

        <div class="my-5">
          <p class="imp-header">Haftung für Links</p>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p class="mt-3">
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
        </div>

        <div class="mt-5">
          <p class="imp-header">Urheberrecht</p>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </p>
          <p class="mt-3">
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
        </div>
      </div>
    </div>
    <!-- ENGLISH -->
    <div v-else-if="$i18n.locale === 'en'">
      <div class="grid grid-cols-2 grid-rows-auto leading-9 text-darkerGray">
        <div class="mb-10">
          <p class="imp-header">Disclosures according to § 5 TMG</p>
          <p>ESG Analytics GmbH</p>
          <p>Von-Werth-Straße 15</p>
          <p>50670 Cologne</p>
          <p>Germany</p>
          <p class="mt-4">Commercial Register: HRB 61018</p>
          <p>Register court: Cologne Local Court</p>
          <p class="mt-4">Represented by:</p>
          <p>Dr. Markus G. Bell (Managing Director)</p>
        </div>
        <div>
          <p class="imp-header">Contact</p>
          <p>support@ecore-rating.com</p>
        </div>
        <div>
          <p class="imp-header">Editor in charge</p>
          <p>Dr. Markus G. Bell</p>
          <p>ESG Analytics GmbH</p>
          <p>Von-Werth-Straße 15</p>
          <p>50670 Cologne</p>
          <p>info@ecore-scoring.com</p>
          <p>www.ecore-scoring.com</p>
        </div>
      </div>
      <div
        class="grid grid-rows-auto leading-8 mt-7 text-darkerGray text-justify"
      >
        <div class="my-5">
          <p class="imp-header">EU Dispute resolution</p>
          <p>
            The European Commission provides a platform for online dispute
            resolution (OS): https://ec.europa.eu/consumers/odr.
          </p>
          <p class="mt-3">
            You can find our e-mail address in the imprint above.
          </p>
          <p class="mt-3">
            Consumer dispute resolution/universal dispute resolution service.
          </p>
          <p class="mt-3">
            We are not willing or obliged to participate in dispute resolution
            proceedings before a consumer arbitration board.
          </p>
        </div>

        <div class="my-5">
          <p class="imp-header">Liability for content</p>
          <p>
            As a service provider, we are responsible for our own content on
            these pages in accordance with general legislation pursuant to
            Section 7 (1) of the German Telemedia Act (TMG). However, according
            to §§ 8 to 10 TMG, we are not obliged as a service provider to
            monitor transmitted or stored third-party information or to
            investigate circumstances that indicate illegal activity.
          </p>
          <p class="mt-3">
            Obligations to remove or block the use of information in accordance
            with general laws remain unaffected by this. However, liability in
            this regard is only possible from the point in time at which a
            concrete infringement of the law becomes known. If we become aware
            of any such infringements, we will remove the relevant content
            immediately.
          </p>
        </div>

        <div class="my-5">
          <p class="imp-header">Liability for links</p>
          <p>
            Our website contains links to external websites of third parties
            over whose content we have no influence. Therefore, we cannot assume
            any liability for these external contents. The respective provider
            or operator of the pages is always responsible for the content of
            the linked pages. The linked pages were checked for possible legal
            violations at the time of linking. Illegal contents were not
            recognisable at the time of linking.
          </p>
          <p class="mt-3">
            However, permanent monitoring of the content of the linked pages is
            not reasonable without concrete indications of a legal violation. If
            we become aware of any infringements of the law, we will remove such
            links immediately.
          </p>
        </div>

        <div class="mt-5">
          <p class="imp-header">Copyright</p>
          <p>
            The contents and works on these pages created by the site operators
            are subject to German copyright law. Duplication, processing,
            distribution, or any form of commercialization of such material
            beyond the scope of the copyright law shall require the prior
            written consent of its respective author or creator. Downloads and
            copies of this site are only permitted for private, non-commercial
            use.
          </p>
          <p class="mt-3">
            Insofar as the content on this site was not created by the operator,
            the copyrights of third parties are respected. In particular,
            third-party content is marked as such. Should you nevertheless
            become aware of a copyright infringement, please inform us
            accordingly. If we become aware of any infringements, we will remove
            such content immediately.
          </p>
        </div>
      </div>
    </div>
  </StaticView>
</template>

<script>
import StaticView from '@/components/layout/StaticView'
export default {
  components: {
    StaticView,
  },
}
</script>

<style scoped>
.imp-header {
  @apply text-[20px] font-semibold mb-[1rem] uppercase;
}
</style>
